// scss-lint:disable SelectorDepth, NestingDepth
$susy: ( columns: 12, guters: 1 / 4, math: fluid, output: float, gutter-position: inside, debug: (image: hide, output: background), use-custom: (box-sizing: false) );

body {
  background: $white url('../images/vYXGm9m.png');
}

img {
  max-width: 100%;
}

.container {
  @include container($desktop);
}

.wrap {
  background: #ededed; // scss-lint:disable ColorVariable
}

.clearfix {
  clear: both;
  width: 100%;
}

.main {
  padding: 1px 15px 15px;
}
// Page Home
.home main {
  @include container;

  padding: 0 0 15px;

  section {
    @include span(12 of 12);
    @include susy-breakpoint($tablet) {
      @include span(9 of 12);
    }
  }

  aside {
    @include span(12 of 12);
    @include susy-breakpoint($tablet) {
      @include span(3 of 12);
    }
  }
}

.home article {
  background: $white;
  border: 0 solid $green;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  border-top-width: 3px;
  margin-top: 15px;
  padding: 15px;
}

.muted {
  color: $grey-500;
}

.guideline {
  @include span(6 of 12);
  margin: 15px 0;

  .guideline-inner {
    background: $white;
    border-top: 3px solid $green;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    padding: 15px;
  }

  ul {
    list-style: none;
    padding-left: 0;
  }

  &:nth-child(2n-1) {
    clear: left;
  }
}
.sponsers {
  @include span(12 of 12);
  @include susy-breakpoint($tablet) {
    @include span(9 of 12);
  }
}
.sponser-list {
  @include span(12 of 12);
  @include susy-breakpoint($tablet) {
     @include span(3 of 12);
  }
}

.sponser-column {
  @include span(12 of 12);

  .inner-column {
    border-top: 3px solid $green;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    margin-right: 5px;
    margin-bottom: 5px;
    background: white;
    padding: 20px;
  }
  h3 {
    margin-top: 0;
  }
  .image-wrap {
    height: 200px;

    img {
      max-height: 200px;
      display: block;
      margin: 0 auto;
    }
  } 
  
  @include susy-breakpoint($tablet) {
    &:nth-child(3n-1) {
      clear: left;
    }
  }  

}

%clearfix {
  &::after {
    clear: both;
    content: '';
    display: table;
  }
}
