a,
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  color: $text;
  font-family: 'acumin-pro', sans-serif;
}

h2 {
  color: $text;
  font-family: 'grafolita-script', serif;
  font-size: 1.75em;
}

h3 {
  font-size: 1.1em;
}

.advert {
  font-size: .7em;
}

a {
  color: $text-secondary;
  text-decoration: none;
}
