:root
{
    font-size: 16px;
}

html,
body
{
    height: 100%;
    min-height: 100%;

    -webkit-font-smoothing: antialiased;
            font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
}

textarea,
input[type='text'],
input[type='button'],
input[type='number'],
input[type='submit']
{
    border: none;
    border-radius: 0;

    -webkit-appearance: none;
}

*
{
    box-sizing: border-box;
}

*:focus
{
    outline: 0;
}
