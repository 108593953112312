.distribution-map {
  @include clearfix;

  background: $grey-900;
  margin: 20px 0;
  width: 100%;
}

.map {
  @include span(12 of 12);

  @include susy-breakpoint($tablet) {
    @include span(9 of 12);

    height: 400px;
  }


  img {
    max-width: inherit;
  }
}

.map-search {
  @include span(12 of 12);

  @include susy-breakpoint($tablet) {
    @include span(3 of 12);

    height: 400px;
  }

  h3 {
    color: $white;
    text-align: center;
  }

  input,
  select {
    border: 0;
    margin-bottom: .1em;
    padding: .5em;
    width: 100%;
  }

  button {
    background: $primary;
    border: 0;
    color: $grey-900;
    margin-bottom: .1em;
    padding: .5em;
    width: 100%;
  }
}

.city {
  background: $white;
  border-top: 2px solid $green;
  column-count: 1;
  margin-bottom: 20px;
  padding: 0 15px 5px;

  @include susy-breakpoint($mobile) {
    column-count: 3;
  }

  h2 {
    column-span: all;
  }

  address {
    font-size: .75em;
  }

  .city-inner {
    display: table;
    break-inside: avoid-column;
    page-break-inside: avoid;
    padding-bottom: 1em;
  }
  .collapsed {
    display: block;
  }
}
