.staff-wrapper {
  @extend %clearfix;
}

.staff {
  @include susy-breakpoint($tablet) {
    @include span(2 of 6 before);

    &:nth-child(3n-2) {
      clear: left;
      margin-left: 0;
    }
  }

  .staff-inner {
    @extend %clearfix;

    background: $white;
    border-left: 5px solid $green;
    margin-bottom: 20px;

    img {
      border-radius: 50%;
      float: left;
      margin: 15px;
      max-height: 100px;
      max-width: 100px;
    }

    .info-wrap {
      float: left;
    }

    h4 {
      margin-bottom: .5em;
    }

    .position,
    .staff {
      display: block;
      font-size: .9rem;
      line-height: 1.6em;
    }

    a {
      color: $green;
      text-decoration: none;
    }
  }
}
