// Selector format disabled because gravity forms rules present
// scss-lint:disable SelectorFormat
article {
  h2 {
    font-size: 2em;
    margin-bottom: .5rem;
    margin-top: .25rem;
    padding-bottom: .25rem;
    text-align: center;
  }
}

.slider {
  @extend %clearfix;

  background: $white;
  position: relative;

  .flex-control-nav {
    bottom: -5px;
  }
}


.latest-issue {
  background: $white;
  margin: 20px 0 15px;
  padding: 1px 15px;

  img {
    display: block;
    margin: 10px auto;
  }
}

.btn {
  background: $green;
  color: $grey-900;
  display: block;
  margin: 0 auto 15px;
  padding: 10px;
  text-align: center;
  text-decoration: none;
  width: 70%;
}

.issue-pdf,
.btn-small {
  margin: 1em 0;
  max-width: 100%;
  width: 20em;
}

.events-link {
  @include span(12 of 12);

  padding: 15px;
  text-align: right;

  @include susy-breakpoint($tablet) {
    @include span(first 6 of 12 isolate before);
  }
}

.newsletter {
  @include span(12 of 12);
  padding: 15px;

  @include susy-breakpoint($tablet) {
    @include span(last 6 of 12 isolate after);
  }

  h2 {
    border-bottom: 0;
    text-align: center;
  }

  .gfield_html {
    font-size: 1em;
    font-style: italic;
    line-height: 1.6em;
    padding: 10px;
    text-align: center;
  }

  .gfield_contains_required {
    padding: 10px;
  }

}





.guidelines {
  ul {
    font-size: .85em;
    line-height: 1.4em;
  }
}

.magazine {
  @include clearfix;

  img {
    max-height: 300px;
  }
}

blockquote {
  background: $white;
  border-left: 10px solid $green;
  margin: 1.5em 10px;
  padding: .5em 10px;
  quotes: '“' '”' '‘' '’';

  p {
    display: inline;
  }
}

blockquote::before {
  color: $green;
  content: open-quote;
  font-family: acumin-pro, sans-serif;
  font-size: 4em;
  line-height: .1em;
  margin-right: .25em;
  vertical-align: -.4em;
}

.gform_fields {
  list-style: none;
  padding: 0;

  .gfield {
    position: relative;
  }

  .ginput_container input,
  .ginput_container textarea {
    color: #121212;
    border-radius: .25rem;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.08);
    font-size: .85em;
    border: 1px solid $grey-300;
    margin: 1.5em .3em .3em;
    padding: .75rem;
    width: 100%;
  }

  .ginput_container .gfield_radio input {
    width: auto;
  }

  .gfield_label {
    font-size: .8em;
    left: .3em;
    position: absolute;
    text-transform: uppercase;
    top: .3em;
  }

  .gform_validation_container {
    display: none;
  }

  .gfield_radio {
    padding: 0;
    padding-top: 20px;
    list-style-type: none;
  }

  .gfield_radio li {
    display: inline-block;
    padding-right: 10px;
    vertical-align: middle;
  }

  .gfield_radio li input {
    vertical-align: text-bottom;
  }

  .ginput_container .gfield_radio input {
    margin: 0;
    margin-right: 8px;
    vertical-align: middle;
  }
}

.gform_button {
  @extend .btn;
}

table {
  color: $text;
  max-width: 100%;
  overflow: scroll;
  width: 100%;
  background: white;
}

.rate-card table,
{
  thead {
    border-bottom: 2px dotted $primary;
    background: white;
  }
  
  tr {
    background: $white;
  
    &:nth-child(2n) {
      background: $primary-light;
    }
  
    th {
      padding: .75em;
    }
  
    td {
      border-right: 2px dotted $primary;
      padding: .5em;
    }

    td:first-child {
      text-align: left;
    }
  
    td:last-child {
      border-right-width: 0;
    }
  }
}

.rates {
  p {
    margin: 0;
  }

  td {
    text-align: right;
  }

}

input.button[type="submit"], a.button {
  background: $green;
  color: white;

  &:hover {
    background: darken($green, 10%);
    color: white;
  }
}

input[type="text"], 
input[type="email"], 
input[type="tel"],
input[type="password"] {
  padding: 5px 7px;
  border: 1px solid #999;
}
