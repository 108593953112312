.social-feed
{
    margin: 20px 0;
    padding: 1px 15px;

    background: $white;

    .social-feed-element-inner
    {
        margin: 10px 0;
    }

    .social-feed-element
    {
        .author-title
        {
            font-weight: 500;
            line-height: 1.5em;

            text-transform: capitalize;

            color: #444;
        }

        p
        {
            margin: 0 0 10px;
        }

        .media-body
        {
            > p
            {
                min-height: 20px;
                margin-bottom: 10px;
            }

            div
            {
                line-height: 25px;

                color: #666;
            }
        }

        a
        {
            text-decoration: none;

            color: #08c;
        }

        .item-header
        {
            padding-bottom: 5px;

            border-bottom: 1px solid rgba(34, 34, 34, .05);
        }

        .social-feed-text
        {
            word-wrap: break-word;
            // This is the dangerous one in WebKit, as it breaks things wherever
                word-break: break-all;
            // Instead use this non-standard one:
                word-break: break-word;
            -ms-word-break: break-all;
            // scss-lint:disable DuplicateProperty
            hyphens: auto;
            // scss-lint:disable PropertySortOrder
            // These are technically the same, but use both

            overflow-wrap: break-word;
        }
    }
}
