ul.products {
  li {
    background: white;
    border-top: 3px solid $green;
    padding: 15px !important;
    text-align: center;
  }
}
/* Product Single Page */
.woocommerce {
  .product {
    div.images {
      width: 30% !important;
    }
    .summary {
      background: white;
      border-top: 3px solid $green;
      padding: 15px;
    }
  }
  .wc-tabs {

  }
}

nav.woocommerce-MyAccount-navigation {
  width: 20% !important;

  ul { 
    padding: 0px;
    margin: 0px;
    list-style: none;

    li {
      &.woocommerce-MyAccount-navigation-link {
        padding: 8px 30px;
        border-left: 3px solid $green;
        background: white;
      }
    }
  }
}

div.woocommerce-MyAccount-content {
  width: 78% !important;
}

table.order_details {
  tfoot {
    tr {
      background: white;
      font-size: 0.95em;
    }
  }
}

.woocommerce {
  table.shop_table {
    tfoot {
      font-size: 0.95em;
      tr {
        th {
          text-align: right;
        }

        &.recurring-totals {
          th {
            text-align: center;
          }
        }
      }
    }
  }

  ul.woocommerce-thankyou-order-details {
    padding: 20px;
    background: white;
    border-radius: 3px;
    border: 1px solid #d3ced2;
    border-top: 3px solid $green;
  }
}

.woocommerce form.login,
.woocommerce form.woocommerce-ResetPassword,
.woocommerce form.checkout_coupon,
.woocommerce form.checkout,
{
  background: #fff;
  border: 1px solid #d3ced2;
  border-top: 2px solid $green;
  padding: 20px;
  border-radius: 5px;
  margin: 2em 0;
}

.woocommerce-error,
.woocommerce-message {
  background: #fff;
}
