// WordPress Generated Classes
// http://codex.wordpress.org/CSS#WordPress_Generated_Classes

// Media alignment
.alignnone {
  height: auto;
  margin-left: 0;
  margin-right: 0;
  max-width: 100%;
}

.aligncenter {
  display: block;
  height: auto;
  margin: .5em auto;
}

.alignleft,
.alignright {
  height: auto;
  margin-bottom: .5em;
}

@media (min-width: $tablet) {
  // Only float if not on an extra small device
  .alignleft {
    float: left;
    margin-right: .5em;
  }

  .alignright {
    float: right;
    margin-left: .5em;
  }
}
