.brand {
  display: none;
}

.header-navigation {
  @include span(12 of 12);

  display: none;
  font-family: 'acumin-pro', sans-serif;

  @include susy-breakpoint($tablet) {
    @include span(9 of 12);

    background: inherit;
    display: block;
    position: static;

    li {
      display: inline-block;

      a {
        text-align: left;
      }
    }
  }

  .menu-close {
    color: $white;
    display: block;
    font-size: 1.5em;
    left: 0;
    padding: 1rem;
    position: absolute;
    text-decoration: none;
    top: 0;

    @include susy-breakpoint(32em) {
      display: none;
    }
  }

  &.mobile-menu-show {
    background-color: $transparent-black;
    bottom: 0;
    display: block;
    left: 0;
    overflow: scroll;
    padding-top: 50px;
    position: fixed;
    right: 0;
    top: 0;
    transition: background-color 500ms ease-in-out;
    z-index: 999999;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      a {
        display: block;
        padding: 10px;
        text-align: center;
        text-decoration: none;
        text-transform: uppercase;
        color: $white;
        font-size: .8em;
        line-height: 1.75rem;

        &:hover {
          color: $green;
        }
      }
    }
  }
}

.menu-item-has-children {
  position: relative;

  ul {
    border-bottom: 1px solid $grey-50;
    border-top: 1px solid $grey-50;
    margin-bottom: 1em;
    margin-top: 0;
  }

  @include susy-breakpoint($tablet) {
    ul {
      background: $transparent-black;
      display: none;
      left: 0;
      position: absolute;
      top: 100%;
      z-index: 999;

      li {
        white-space: nowrap;
        width: 100%;
      }
    }

    &:hover {
      ul {
        display: block;
      }
    }
  }
}

body > header {
  background: $grey-900;
  border-bottom: 1px solid $green;

  .mobile {
    @include span(3 of 12);
  }

  .menu-open {
    @include susy-breakpoint($tablet) {
      display: none;
    }

    color: $white;
    display: block;
    font-size: 1.5em;
    padding: 1rem;
    text-decoration: none;
  }
}

.header-social {
  @include span(9 of 12);

  font-size: 1.5em;

  @include susy-breakpoint($tablet) {
    @include span(3 at 9 of 12);
    font-size: 1em;
  }
}

.social-list {
  list-style: none;
  margin: 0;
  padding: 0;
  text-align: right;

  li {
    display: inline-block;

    a {
      color: $grey-100;
      display: block;
      font-family: 'acumin-pro';
      font-size: 1em;
      line-height: 1em;
      padding: 1rem;
      text-align: center;
      text-decoration: none;
      text-transform: uppercase;
      transition: all .2s ease-in-out;

      &:hover {
        color: $white;
      }
    }

    .social-twitter:hover {
      background: #54aee1; // scss-lint:disable ColorVariable
    }

    .social-facebook:hover {
      background: #4772af; // scss-lint:disable ColorVariable
    }

    .social-pinterest:hover {
      background: #cb2027; // scss-lint:disable ColorVariable
    }

    .social-instagram:hover {
      background: #406f95; // scss-lint:disable ColorVariable
    }
  }
}

.sub-header {
  @include susy-breakpoint($tablet) {
    background: $grey-100 url('../images/writers.png') no-repeat;
    background-position: right bottom;
    background-size: contain;
    border-bottom: 2px solid $grey-900;
    height: 150px;
  }

  img {
    display: block;
    margin: 0 auto;
    max-height: 120px;
  }

  span {
    display: block;
    font-weight: bold;
    text-align: center;
  }
}
