.contact-us {
  aside,
  section {
    @include span(12 of 12);
    @include susy-breakpoint($tablet) {
      @include span(6 of 12);
    }
  }

  .contact-map {
    float: none;
    height: 250px;
    width: 100%;
  }
}

.contact-info {
  background: $white;
  border-top: 3px solid $primary;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  padding: 15px;

  ul {
    list-style: none;
    margin-top: 10px;
    padding: 0;

    li {
      display: block;
      padding: .15em;
    }

    i {
      width: 30px;
    }

    address {
      display: inline;
    }
  }
}

.issue-thumbnail {
  @include span(12 of 12);

  @include susy-breakpoint($tablet) {
    @include span(3 of 12);
  }

  img {
    background: $white;
    max-width: 15em;
    padding: 10px;
  }
}

.gallery {
  a {
    display: inline-block;
  }

  img {
    background: $white;
    border: 1px solid $divider;
    margin: 5px;
    padding: 5px;
  }
}
