footer {
  background: $grey-900;
  border-bottom: 1px solid $grey-900;
  border-top: 2px solid $green;
  color: $white;

  .container {
    padding-top: 20px;
    text-align: center;
  }

  .logo {
    max-width: 210px;
  }

  ul {
    list-style: none;
    padding: 0;

    li {
      font-size: .9rem;

      padding: .25em;
    }

    i {
      width: 20px;
    }
  }
}
